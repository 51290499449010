import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { DocTypesFeature } from './selectors';
import { GetDocumentTypeDto } from '../models/doc-types.model';

export const DocTypesActions = createActionGroup({
  source: DocTypesFeature,
  events: {
    'Get DocTypes': emptyProps(),
    'Load DocTypes Fail': emptyProps(),
    'Load DocTypes': emptyProps(),
    'Set DocTypes': props<{ docTypes: GetDocumentTypeDto[] }>(),
    'Create DocType': props<{ name: string }>(),
    'Create DocType Success': props<{ docType: GetDocumentTypeDto }>(),
    'Create DocType Fail': emptyProps(),
    'Create DocType Cancel': props<{ pristine: boolean }>(),
    'Create DocType Cancelled': emptyProps(),
    'Clear Create DocType Cancelled': emptyProps(),
    'Close Creating DocType Modal': emptyProps(),
    'Delete DocType': props<{ id: string }>(),
    'Delete DocType Confirmed': props<{ id: string }>(),
    'Delete DocType Success': emptyProps(),
    'Delete DocType Fail': emptyProps(),
    'Delete DocType Cancel': emptyProps(),
    'Load DocType': props<{ id: string }>(),
    'Load DocType Fail': emptyProps(),
    'Set DocType': props<{ docType: GetDocumentTypeDto }>(),
    'Edit DocType': props<{ name: string; id: string }>(),
    'Edit DocType Success': props<{ docType: GetDocumentTypeDto }>(),
    'Edit DocType Fail': emptyProps(),
    'Edit DocType Cancel': props<{ pristine: boolean }>(),
    'Edit DocType Cancelled': emptyProps(),
    'Clear Edit DocType Cancelled': emptyProps(),
    'Close Editing DocType Modal': emptyProps(),
  },
});
